import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AnalyticsProvider } from "providers/analytics/analytics";

@Component({
  selector: "app-pan-verification",
  templateUrl: "./pan-verification.page.html",
  styleUrls: ["./pan-verification.page.scss"],
})
export class PanVerificationPage implements OnInit {
  user_data;
  constructor(
    private modalController: ModalController,
    private analytics: AnalyticsProvider
  ) {}

  ngOnInit() {}
  ionViewDidEnter() {
    console.log(this.user_data, "oooo");
    this.analytics.trackScreen("PanVerificationPage");
  }

  closeModal(state?) {
    let data = { type: state, data: this.user_data };
    this.modalController.dismiss(data);
  }
}
