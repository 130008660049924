import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { ReferEarnPageRoutingModule } from "./refer-earn-routing.module";

import { ReferEarnPage } from "./refer-earn.page";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReferEarnPageRoutingModule,
    TranslateModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
  ],
  declarations: [ReferEarnPage],
})
export class ReferEarnPageModule {}
