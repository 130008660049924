import { Injectable } from "@angular/core";
import {
  Camera,
  CameraResultType,
  CameraSource,
  CameraPermissionState,
} from "@capacitor/camera";
import { Subject } from "rxjs";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { ToasterService } from "../toaster/toaster.service";

@Injectable({
  providedIn: "root",
})
export class MediaServiceService {
  private video: HTMLVideoElement;
  private eventCallback = new Subject<string>();
  returnImage = this.eventCallback.asObservable();

  base64Image: any;
  constructor(private toast: ToasterService) {}
  // Corodova
  captureImage() {
    // const options = {
    //   quality: 40,
    //   destinationType: this.camera.DestinationType.DATA_URL,
    //   encodingType: this.camera.EncodingType.JPEG,
    //   mediaType: this.camera.MediaType.PICTURE,
    //   cameraDirection: this.camera.Direction.BACK,
    //   correctOrientation: true,
    // };
    // this.camera.getPicture(options).then(
    //   (imageData) => {
    //     this.base64Image = imageData;
    //     this.eventCallback.next(this.base64Image);
    //   },
    //   (err) => {
    //     if (err == "No Image Selected") {
    //       this.eventCallback.next("cancelled");
    //       return;
    //     }
    //     // Handle error
    //     this.androidPermissions
    //       .checkPermission(this.androidPermissions.PERMISSION.CAMERA)
    //       .then((result) => {
    //         if (result.hasPermission) {
    //           console.log("Has permission?", result.hasPermission);
    //         } else {
    //           this.androidPermissions.requestPermission(
    //             this.androidPermissions.PERMISSION.CAMERA
    //           );
    //         }
    //       });
    //   }
    // );
  }

  gallery() {
    // const options: CameraOptions = {
    //   quality: 40,
    //   destinationType: this.camera.DestinationType.DATA_URL,
    //   encodingType: this.camera.EncodingType.JPEG,
    //   mediaType: this.camera.MediaType.PICTURE,
    //   correctOrientation: true,
    //   sourceType: 0,
    // };
    // this.camera.getPicture(options).then(
    //   (imageData) => {
    //     console.log(this.camera.Direction.BACK);
    //     console.log(this.camera.Direction.FRONT);
    //     this.base64Image = imageData;
    //     this.eventCallback.next(this.base64Image);
    //   },
    //   (err) => {
    //     // Handle error
    //     if (err == "No Image Selected") {
    //       this.eventCallback.next("cancelled");
    //       return;
    //     }
    //     this.androidPermissions
    //       .checkPermission(this.androidPermissions.PERMISSION.CAMERA)
    //       .then((result) => {
    //         if (result.hasPermission) {
    //           console.log("Has permission?", result.hasPermission);
    //         } else {
    //           this.androidPermissions.requestPermission(
    //             this.androidPermissions.PERMISSION.CAMERA
    //           );
    //         }
    //       });
    //   }
    // );
  }

  async takePicture(type) {
    try {
      const image = await Camera.getPhoto({
        quality: 40,
        allowEditing: true,
        source: CameraSource.Camera,
        resultType: CameraResultType.Base64,
      });
      console.log("image", image);

      var imageUrl = "data:image/png;base64," + image.base64String;

      // Can be set to the src of an image now
      this.base64Image = imageUrl;
      return this.base64Image;
    } catch (err) {
      // this.toast.presentToast('Please upload a Invoice image')
      return "err";
    }
  }
  async checkPermission() {
    let permission = await Camera.checkPermissions();
    if (permission.camera == "prompt" || permission.camera == "granted") {
      this.requestPermission();
    }
  }

  async requestPermission() {
    try {
      let request = await Camera.requestPermissions({
        permissions: ["camera"],
      });
      console.log(request);
    } catch (err) {
      console.log("request denied", err);
    }
  }
}
