import { Component, ChangeDetectorRef } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AuthenticationService } from "./services/authguard/authentication.service";
import { Router } from "@angular/router";
import { UpdateService } from "./services/update/update.service";
import { FCM } from "cordova-plugin-fcm-with-dependecy-updated/ionic";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "./services/language/language.service";
import { MediaMatcher } from "@angular/cdk/layout";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  language_key: any;
  selectedPlatform: any = "mobile";
  isSmallScreen: boolean;
  private readonly mobileQuery: MediaQueryList;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private authenticationService: AuthenticationService,
    private router: Router,
    private updateService: UpdateService,
    private transLateService: TranslateService,
    private languageService: LanguageService,
    private toastController: ToastController,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.initializeApp();
    this.languageService.setInitialAppLanguage();

    this.mobileQuery = media.matchMedia("(max-width: 720px)");
    this.isSmallScreen = this.mobileQuery.matches;

    this.mobileQuery.addEventListener("change", () => {
      changeDetectorRef.detectChanges();
      this.isSmallScreen = this.mobileQuery.matches;
    });
  }

  initializeApp() {
    window.localStorage.setItem("brand_name", "Bigcity");
    this.platform.ready().then(() => {
      // check for updates
      if (this.platform.is("mobile")) {
        this.selectedPlatform = "mobile";
        // this.updateService.descideUpdateAction();
      } else {
        this.selectedPlatform = "browser";
      }
      this.statusBar.styleDefault();
      this.statusBar.overlaysWebView(false);
      this.splashScreen.hide();
      // this.checkAndRedirect();
      this.authenticationService.authenticationState.subscribe((state) => {
        if (state) {
          this.router.navigate(["/tabs-page/dashboard"]);
          // this.router.navigate(['/tabs-page/dashboard/qr-sales-demo/point-credit/2']);
        } else {
          this.router.navigate(["/onboarding/landing-page"]);
          // this.router.navigate(['/onboarding/self-enrollment/9']);
        }
      });
      if (this.platform.is("cordova")) {
        FCM.getToken()
          .then((token) => {
            localStorage.setItem("fcm", token);
          })
          .catch((err) => {
            // console.log('err:::', err)
          });

        FCM.onNotification().subscribe((data) => {
          if (data.wasTapped) {
            // console.log("Received in background");
          } else {
            // console.log("Received in foreground");
            this.pushLocalNotification(data);
          }
        });

        FCM.onTokenRefresh().subscribe((token) => {
          // backend.registerToken(token);

          // console.log('refresh token', token)
          localStorage.setItem("fcm", token);
        });

        FCM.hasPermission().then((hasPermission) => {
          if (hasPermission) {
            // console.log("Has permission!");
          } else {
            // console.log('No permission')
            FCM.requestPushPermission().then(
              (res) => {
                // console.log("Permission accepted")
              },
              (err) => {
                // console.log("Permission rejected")
              }
            );
          }
        });
        FCM.clearAllNotifications();
      }

      // let status bar overlay webview
      // this.statusBar.overlaysWebView(true);
      // set status bar to white
      this.statusBar.backgroundColorByHexString("#1A1A1A");
    });
    this.platform.resume.subscribe(async () => {
      //Check for updates
      if (this.platform.is("mobile")) {
        // this.updateService.descideUpdateAction();
      }
    });

    // let language = localStorage.getItem('lang_text')
    // console.log("selected language",language)
    // if(language){
    //   this.transLateService.use(language);
    // }else{
    //   this.transLateService.use('en');

    // }
    this.callTranslationEvent();
  }

  async checkAndRedirect() {
    let state = await this.authenticationService.isAuthenticated();
    //console.log(state);
  }

  pushLocalNotification(data) {
    console.log("notification data::", data);
    // this.localNotifications.schedule({
    //   foreground: true,
    //   trigger: {at: new Date(new Date().getTime() + 100)},
    //   title: 'My first notification',
    //   text: 'Thats pretty easy...',
    // });

    // this.localNotifications.fireEvent
    // console.log('local notification triggered')
    this.presentToastWithOptions(data);
  }

  async presentToastWithOptions(data) {
    const toast = await this.toastController.create({
      header: "V-Scan",
      message: data.body,
      position: "top",
      cssClass: "notification-alert",
      duration: 5000,
      buttons: [
        {
          side: "end",
          // icon: 'star',
          cssClass: "notification-button",
          text: "VIEW",
          handler: () => {
            this.router.navigateByUrl("/tabs-page/dashboard/notification");
          },
        },
      ],
    });
    toast.present();
  }

  callTranslationEvent() {
    this.transLateService.setDefaultLang("en");
    this.languageService.$changeLanguage.subscribe((lang) => {
      this.language_key = lang;
      //console.log("change Language = ", this.language_key);
      if (
        this.language_key["type"] == "language" &&
        this.language_key["data"] == "en"
      ) {
        this.transLateService.use(this.language_key["data"]);
      } else if (
        this.language_key["type"] == "language" &&
        this.language_key["data"] == "tm"
      ) {
        this.transLateService.use(this.language_key["data"]);
      }
    });
  }
}
