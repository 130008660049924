import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { CommonModule } from "@angular/common";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { HttpConfigInterceptor } from "./services/interceptors/http-loading-interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./utils/sharedModules/material.module";
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthguardService } from "./services/authguard/authguard.service";
import { SharedModule } from "./shared/shared.module";
import { IonicStorageModule } from "@ionic/storage";
import { YoutubeVideoPlayer } from "@ionic-native/youtube-video-player/ngx";
import {
  StreamingMedia,
  StreamingVideoOptions,
} from "@ionic-native/streaming-media/ngx";
import { AuthenticationService } from "./services/authguard/authentication.service";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { ScreenOrientation } from "@ionic-native/screen-orientation/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import {
  TranslateFakeLoader,
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from "@ionic-native/market/ngx";
import { IonicSelectableModule } from "ionic-selectable";
import { FCM } from "@ionic-native/fcm/ngx";
import { LanguageService } from "./services/language/language.service";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { ComponentsModule } from "./components/components.module";
import { BankVerificationModelPageModule } from "./mvp/onboarding/bank-details/bank-verification-model/bank-verification-model.module";

import { ReferEarnPageModule } from "./mvp/refer-earn/refer-earn.module";
import { CommonSuccesModalPageModule } from "./mvp/users/common-succes-modal/common-succes-modal.module";

import { ScannerModalPageModule } from "./mvp/scan-earn/scanner-modal/scanner-modal.module";

import { FirebaseAnalytics } from "@ionic-native/firebase-analytics/ngx";
import { AnalyticsProvider } from "providers/analytics/analytics";
import { OrdersummaryModalPageModule } from "./mvp/order-summary/ordersummary-modal/ordersummary-modal.module";
import { OrderSuccessModalPage } from "./mvp/order-summary/order-success-modal/order-success-modal.page";
import { OrderSuccessModalPageModule } from "./mvp/order-summary/order-success-modal/order-success-modal.module";
import { HtmlSafePipe } from "./pipes/html-safe.pipe";
import { BrandModalPage } from "./mvp/brand-zone/brand-modal/brand-modal.page";
import { IdProofPageModule } from "./mvp/onboarding/id-proof/id-proof.module";
import { IdProofOtpPageModule } from "./mvp/onboarding/id-proof-otp/id-proof-otp.module";
import { AadhaarOtpPageModule } from "./mvp/onboarding/aadhaar-otp/aadhaar-otp.module";
import { PanVerificationPageModule } from "./mvp/onboarding/pan-verification/pan-verification.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, HtmlSafePipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      swipeBackEnabled: false,
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    IonicSelectableModule,
    ReactiveFormsModule,
    CommonModule,
    ComponentsModule,
    OrderSuccessModalPageModule,
    AadhaarOtpPageModule,
    ReferEarnPageModule,
    CommonSuccesModalPageModule,
    SharedModule,
    ScannerModalPageModule,
    BankVerificationModelPageModule,
    OrdersummaryModalPageModule,
    PanVerificationPageModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    StatusBar,
    FCM,
    SplashScreen,
    AuthguardService,
    AuthenticationService,
    AndroidPermissions,
    Camera,
    BarcodeScanner,
    ScreenOrientation,
    YoutubeVideoPlayer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    StreamingMedia,
    Geolocation,
    Market,
    AppVersion,
    InAppBrowser,

    Diagnostic,
    LanguageService,
    SocialSharing,
    AnalyticsProvider,
    FirebaseAnalytics,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
