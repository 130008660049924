import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError, pipe } from "rxjs";
import {
  map,
  catchError,
  retryWhen,
  delay,
  take,
  tap,
  finalize,
} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";
import { ToasterService } from "../toaster/toaster.service";
import { AuthenticationService } from "../authguard/authentication.service";
import { Router } from "@angular/router";
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private loadingCntr: LoadingController,
    private toast: ToasterService,
    private auth: AuthenticationService,
    private router: Router
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //Authentication by setting header with token value
    let token = this.auth.getAuthToken();
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }
    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        setHeaders: {
          "content-type": "application/json",
        },
      });
    }
    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    this.loadingCntr.getTop().then((hasLoading) => {
      if (!hasLoading) {
        this.loadingCntr
          .create({
            cssClass: "custom-loader",
            spinner: "dots",
            message: "Please wait...",
            // duration: 5000,
            translucent: true,
          })
          .then((loading) => loading.present());
      }
    });
    return next.handle(request).pipe(
      // retryWhen(err=>{
      //     let retries =1;
      //     return err.pipe(
      //         delay(1000),
      //         tap(()=>{
      //             console.log("Retry");
      //         }),
      //         map(error=>{
      //             if(retries++ === 3){
      //                 return error
      //             }
      //             return error
      //         })
      //     )
      // }),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = "";
        let toasterMessage = "";
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
          toasterMessage = error.error.message;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          toasterMessage = error.error.data.message.replace(/"/g, "");
        }
        this.toast.presentToast(toasterMessage);
        if (error.error.data.status == 401) {
          this.auth.logout().then(() => {
            this.router.navigate(["/onboarding/mobile-number"], {
              replaceUrl: true,
            });
            let fcmToken = localStorage.getItem("fcm");
            window.localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem("fcm", fcmToken);
          });
        }
        // console.log(toasterMessage);
        try {
          if (
            toasterMessage.toLowerCase() == "no access to this module" ||
            toasterMessage.toLowerCase() == "unauthorized user access!!" ||
            toasterMessage.toLowerCase() ==
              "access token is not set permission denied!"
          ) {
            // console.log(toasterMessage);

            let fcmToken = localStorage.getItem("fcm");
            window.localStorage.clear();
            localStorage.setItem("fcm", fcmToken);

            setTimeout(() => {
              // this.toast.presentToast("You are redirecting to login!");
              this.router.navigate(["/onboarding/mobile-number"]);
            }, 500);
          }
        } catch (error) {}

        this.loaderDismiss();
        return throwError(errorMessage);
      }),
      finalize(() => {
        this.loadingCntr.getTop().then((hasLoading) => {
          this.loaderDismiss();

          // if (hasLoading) {
          //   this.loaderDismiss();
          // }
        });
        setTimeout(() => {
          this.loadingCntr.getTop().then((hasLoading) => {
            if (hasLoading) {
              this.loaderDismiss();
            }
          });
        }, 2500);
      })
    );
  }
  async loaderDismiss() {
    try {
      await this.loadingCntr.dismiss();
    } catch (error) {}
  }
}
