import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthguardService } from "./services/authguard/authguard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "onboarding",
    pathMatch: "full",
  },
  {
    path: "onboarding",
    loadChildren: () =>
      import("./mvp/onboarding/onboarding.module").then(
        (m) => m.OnboardingPageModule
      ),
  },

  {
    path: "tabs-page",
    // canActivate:[AuthguardService],
    loadChildren: () =>
      import("./mvp/tabs-page/tabs-page.module").then(
        (m) => m.TabsPagePageModule
      ),
  },
  {
    path: "rootTabs",
    canActivate: [AuthguardService],
    loadChildren: () =>
      import("./shared/tabs/tabs.module").then((m) => m.TabsPageModule),
  },

  {
    path: "dashboard",
    // canActivate:[AuthguardService],
    loadChildren: () =>
      import("./mvp/dashboard/dashboard.module").then(
        (m) => m.DashboardPageModule
      ),
  },

  {
    path: "profilepage",
    loadChildren: () =>
      import("./mvp/users/profilepage/profilepage.module").then(
        (m) => m.ProfilepagePageModule
      ),
  },

  {
    path: "pointledger",
    loadChildren: () =>
      import("./mvp/users/pointledger/pointledger.module").then(
        (m) => m.PointledgerPageModule
      ),
  },
  {
    path: "order-summary",
    loadChildren: () =>
      import("./mvp/order-summary/order-summary.module").then(
        (m) => m.OrderSummaryPageModule
      ),
  },
  {
    path: "common-succes-modal",
    loadChildren: () =>
      import("./mvp/users/common-succes-modal/common-succes-modal.module").then(
        (m) => m.CommonSuccesModalPageModule
      ),
  },

  {
    path: "scan-earn",
    loadChildren: () =>
      import("./mvp/scan-earn/scan-earn.module").then(
        (m) => m.ScanEarnPageModule
      ),
  },
  {
    path: "refer-earn",
    loadChildren: () =>
      import("./mvp/refer-earn/refer-earn.module").then(
        (m) => m.ReferEarnPageModule
      ),
  },
  {
    path: "pan-verification",
    loadChildren: () =>
      import("./mvp/onboarding/pan-verification/pan-verification.module").then(
        (m) => m.PanVerificationPageModule
      ),
  },
  {
    path: "id-proof-otp",
    loadChildren: () =>
      import("./mvp/onboarding/id-proof-otp/id-proof-otp.module").then(
        (m) => m.IdProofOtpPageModule
      ),
  },  {
    path: 'rewards',
    loadChildren: () => import('./mvp/rewards/rewards.module').then( m => m.RewardsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
