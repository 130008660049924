import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AnalyticsProvider } from "providers/analytics/analytics";
import { ConfirmAlertService } from "src/app/services/confirmAlert/confirm-alert.service";
import { ApiConfiguration } from "src/app/services/http/api-configuration";
import { ApiService } from "src/app/services/http/api.service";
import { localeData } from 'moment';

@Component({
  selector: "app-aadhaar-otp",
  templateUrl: "./aadhaar-otp.page.html",
  styleUrls: ["./aadhaar-otp.page.scss"],
})
export class AadhaarOtpPage implements OnInit {
  user_data: any = "";
  formGroup: any;
  verifyPanRes: any = {};
  submitted: boolean = false;
  backButtonSub: any;
  closeModalData: any = {
    modal_status: "close",
  };
  localData:any = {}

  constructor(
    private modalController: ModalController,
    private analytics: AnalyticsProvider,
    private fb: FormBuilder,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private alertService: ConfirmAlertService,
    private translate: TranslateService,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.updateProfileForm();
  }

  ionViewWillEnter() {
    console.log(this.user_data);
    this.backButtonSub = this.platform.backButton.subscribeWithPriority(1, () => {
        this.backButtonSub.unsubscribe();
      });
  }

  ionViewDidEnter() {
    this.analytics.trackScreen("AadhaarOtpPage");
    this.localData = JSON.parse(localStorage.getItem('login-details'))
  }

  closeModal(data: any) {
    console.log(data);
    if (data == "close") {
      data.modal_status = "close";
    }
    setTimeout(() => {
      this.modalController.dismiss(data);
    }, 100);
  }

  updateProfileForm() {
    this.formGroup = this.fb.group({
      otp: new FormControl("", [
        Validators.required,
        Validators.maxLength(6),
        Validators.minLength(6),
      ]),
    });
  }

  get f() {
    return this.formGroup.controls;
  }

  onSubmit(value: any) {
    this.submitted = true;
    if (this.formGroup.invalid) {
      this.submitted = false;
      return;
    }
    let payload = {
      otp: value.otp,
      ref_id: this.user_data.ref_id,
      aadhaar_number: this.user_data.aadhaar_number,
      // mobile_number: this.user_data.mobile_number,
      program_id: 330,
      user_id : this.localData.user_id
    };
    console.log(payload);
    this.apiService.post(this.apiConfig.aadharOTP, payload).subscribe(
      (res: any) => {
        console.log(res);
        let data = { modal_status: "success", ...res.data };
        this.submitted = false;
        this.closeModal(data);
      },
      (err: any) => {
        this.submitted = false;
      }
    );
  }
}
